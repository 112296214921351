<template>
    <div id="user-box">

        <!-- 查询条件 -->
        <el-form :inline="true" :model="search" class="demo-form-inline" style="text-align:center;">
            <el-form-item label="姓名:">
                <el-input v-model="search.user_name" placeholder="姓名"></el-input>
            </el-form-item>
            <el-form-item label="电话:">
                <el-input v-model="search.phone" placeholder="电话"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="ToSearch">查询</el-button>
            </el-form-item>
        </el-form>

        <!-- 订单列表 -->
        <el-table :data="userList" :cell-style="{ padding: '2px 0' }" height="634" border stripe>
            <el-table-column prop="id" label="id" align="center" width="80"></el-table-column>
            <el-table-column prop="user_name" label="姓名" align="center" width="80"></el-table-column>
            <el-table-column prop="phone" label="手机号" align="center" width="120"></el-table-column>
            <el-table-column prop="order_no" label="商户订单号" align="center" width="180"></el-table-column>
            <el-table-column label="价格" width="100" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.price / 100 }} 元</span>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="商品名" align="center" width="180"></el-table-column>
            <el-table-column label="付款状态" width="120" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.pay_state == 1" style="color:green;">已付款</span>
                    <el-button v-if="scope.row.pay_state == 2" @click="toPay(scope.row)" type="primary">未付款</el-button>
                </template>
            </el-table-column>

            <el-table-column prop="pay_time" label="付款时间" align="center" width="180"></el-table-column>

            <el-table-column label="使用状态" width="180" align="center">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.use_state == 1" @click="toHeXiao(scope.row)"
                        type="primary">已使用</el-button>
                    <el-button v-if="scope.row.use_state == 2" @click="toHeXiao(scope.row)"
                        type="primary">未使用</el-button>
                </template>
            </el-table-column>

            <el-table-column prop="use_time" label="使用时间" align="center" width="180"></el-table-column>
        </el-table>
        <!-- 页码 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="page.CurrPage" :page-sizes="[10, 15, 20, 25]" :page-size="page.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="page.DataCount" :sizes='page.PageCount'>
        </el-pagination>
    </div>
</template>

<script>
import { getForm, postJson, dateFormat, copy } from "../tools/tool"

export default {
    name: "UserView",
    data() {
        return {
            search: {
                CurrPage: 1,
                PageSize: 10,
            },
            page: {
                CurrPage: 1, //初始页
                PageSize: 10, // 每页的数据
                DataCount: 0, // 总条数
                PageCount: 0, // 总页数
            },
            userList: [],
        }
    },

    created() {
        this.onSubmit()
    },
    mounted() { },

    methods: {
        onSubmit() {
            getForm('/getAllOrderList', this.search)
                .then(data => {
                    this.userList = data.data
                    this.page = data.page
                    console.log('userlist:', this.userList)
                }).catch(err => {
                    alert(err)
                })
        },
        ToSearch() {
            this.onSubmit()
        },

        toHeXiao(item) {
            let req = copy(item)
            if (req.use_state == 1) {
                req.use_state = 2
            } else {
                req.use_state = 1
            }

            req.use_time = dateFormat(new Date())
            console.log("item: ", item);
            console.log("item copy: ", req);
            postJson('/updateOrder', req)
                .then(data => {
                    item.use_state = req.use_state
                    item.use_time = req.use_time
                    console.log("data : ", data)
                }).catch(err => {
                    alert(err)
                })
        },

        // 初始页current_page、初始每页数据数page_size和数据data
        handleSizeChange: function (size) {
            this.search.PageSize = size;
            this.onSubmit()
        },
        handleCurrentChange: function (CurrPage) {
            this.search.CurrPage = CurrPage;
            console.log("CurrPage: ", this.search.CurrPage)
            console.log(this.search.CurrPage) //点击第几页
            this.onSubmit()
        },
    }
}

</script>

<style scoped lang="less">

</style>
